/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.cdnfonts.com/css/outfit');

// fonts
$base-font-size: 15;
$base-font: 'Outfit', sans-serif;
$heading-font: 'Outfit';
$heading-font-sub: 'Outfit';

// color
$dark-gray: #232f4b;
$body-color: #4f555a;
$white: #fff;
$light: #8188a9;
$black: #000;
$cyan: #848892;

$theme-primary-color: #3757f7;
$theme-primary-color-s2: #071e93;
$body-bg-color: #fff;
$section-bg-color: #f4f8fc;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #e1e1e1;
$border-color-s2: #d8e0f1;

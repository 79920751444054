/*--------------------------------------------------------------
6. Home-style-3
--------------------------------------------------------------*/

/* 6.1	wpo-features-section-s2 */

.wpo-features-section.wpo-features-section-s2 {
  margin-top: -60px;
  z-index: 11;
  position: relative;
  border: 0;

  @media (min-width: 991px) {
    padding-top: 0;
  }
  @media (max-width: 991px) {
    margin-top: 0;
  }

  .wpo-features-item {
    min-height: 392px;
    padding: 50px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
    border-radius: 16px;
    .wpo-features-text {
      h2 {
        @media (max-width: 1200px) {
          font-size: 25px;
        }
      }
    }

    @media (max-width: 1600px) {
      padding: 60px 40px;
    }
    @media (max-width: 1200px) {
      padding: 30px;
    }

    .wpo-features-icon {
      border-radius: 50%;
    }
  }
}

/* 6.2	wpo-service-section-s3 */

.wpo-service-section.wpo-service-section-s3 {
  .slick-slide {
    padding: 0 10px;
  }
  .slick-dots li button:before {
    font-size: 10px;
    color: $theme-primary-color;
  }
  .wpo-service-item {
    padding: 50px 40px;
    text-align: center;
    position: relative;
    .wpo-service-text {
      h2 {
        font-size: 25px;
      }
    }

    &::before {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 10px;
      content: '';
      background: $theme-primary-color;
      transition: all 0.3s;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }

    .wpo-service-icon {
      width: 90px;
      height: 90px;
      line-height: 108px;
      background: #f4f6fd;
      position: relative;
      display: inline-block;
      z-index: 1;
      @include rounded-border(50%);

      .fi {
        font-size: 50px;
      }

      .fi:before {
        color: $theme-primary-color;
      }
    }
  }

  .owl-nav {
    display: none;
  }

  .owl-dots {
    text-align: center;

    button {
      width: 10px;
      height: 12px;
      border-radius: 50%;
      border: 0;
      margin: 5px;
      background-color: #d6d6d6;

      &.active {
        background-color: $theme-primary-color;
      }
    }
  }
}
